@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/proximanova-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto-regular.woff2') format('woff2'),
  url('./assets/fonts/roboto-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.button-primary.vby {
    width: 100%;
    border: 2px solid;
    border-radius: 15px;
    color: #e21520;
    background-color: #fff;
    font-size: 1.2em;
    padding: 15px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
}

.button-primary.vby:hover {
  color: #fff;
  background-color: #e21520;
}

.button-primary.cvz
{
  width: 100%;
  background-color: #F9A11B;
  color: white;
  border: none;
  font-size: 1.2em;
  padding: 15px;
  box-shadow: 0px 4px 0px 0px #8B6727;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
}

.button-skip.cvz, .button-skip.vby
{
  float: right;
  background-color: #fff;
  width: auto;
  color: #7f7f7f;
  border: 1px solid #b2b2b2;
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
}

.button-skip.cvz:hover, .button-skip.vby:hover
{
  background-color: #fff;
  color: #2a2a2a;
}

.datepicker input {
    cursor: pointer!important;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  background-color: rgb(249 249 250 );
}

h1.vby, h2.vby, h3.vby, h4.vby, h5.vby, h6.vby, .h1.vby, .h2.vby, .h3.vby, .h4.vby, .h5.vby, .h6.vby
{
  font-family: "Proxima Nova", Arial, sans-serif;
  color: #133768;
}

h1.cvz, h2.cvz, h3.cvz, h4.cvz, h5.cvz, h6.cvz, .h1.cvz, .h2.cvz, .h3.cvz, .h4.cvz, .h5.cvz, .h6.cvz
{
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 700;
}
